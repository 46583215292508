import axiosInstance from '../../../Api';

export class ApiService {
  getAllClients (portalId) {
    const url = deltaXApiUrl.clients.getAll
      .replace('{portalId}', deltax.BillingPortalId)
      .concat(`?businessProfileId=${deltax.businessProfileId}`);

    const promise = axiosInstance.get(url);
    return promise;
  }
  getAllAgencyUnits (portalId) {
    const url = deltaXApiUrl.agencyUnits.getAll
      .replace('{portalId}', deltax.BillingPortalId)
      .concat(`?businessProfileId=${deltax.businessProfileId}`);

    const promise = axiosInstance.get(url);
    return promise;
  }
  getAllContracts (portalId, clientIds, agencyUnitIds) {
    const url = deltaXApiUrl.contracts.getAll
      .replace('{portalId}', deltax.BillingPortalId)
      .concat(`?clientIds=${clientIds}`)
      .concat(`&agencyUnitIds=${agencyUnitIds}`);

    const promise = axiosInstance.get(url);
    return promise;
  }
  createApprovedBuy (portalId, formData) {
    const url = deltaXApiUrl.approvedBuys.create
      .replace('{portalId}', deltax.BillingPortalId)

    const promise = axiosInstance({
      url: url,
      method: 'POST',
      data: formData
    });
    return promise
  }
  updateMediaPlan (mediaPlanId, contractId, selectedApprovalGroup) {
    var paramsObject;
    if (selectedApprovalGroup > 0) {
      paramsObject = "[{'Name':'CONTRACTID','Value':" + contractId + "},{'Name':'APPROVALGROUPID','Value':" + selectedApprovalGroup + "}]"
    } else {
      paramsObject = "[{'Name':'CONTRACTID','Value':" + contractId + "}]"
    }
    const updateStatusUrl = deltaXApiUrl.mediaPlans.updateMediaPlan
      .replace('{businessProfileId}', deltax.businessProfileId)
      .replace('{mediaPlanId}', mediaPlanId)

    const promise = axiosInstance.patch(updateStatusUrl, JSON.stringify(paramsObject));
    return promise;
  }
  getAllPurchaseOrders (portalId) {
    const url = deltaXApiUrl.purchaseOrders.getAll
      .replace('{portalId}', deltax.BillingPortalId);

    const promise = axiosInstance.get(url);
    return promise;
  }

  getMediaPlanById (mediaPlanId) {
    const url = deltaXApiUrl.mediaPlans.getById
      .replace('{businessProfileId}', deltax.businessProfileId)
      .replace('{mediaPlanId}', mediaPlanId)

    const promise = axiosInstance.get(url);
    return promise;
  }

  sendToReview (mediaPlanId) {
    var paramsObject = "[{'Name':'STATUS','Value':'5'}]"
    const updateStatusUrl = deltaXApiUrl.mediaPlans.updateMediaPlan
      .replace('{businessProfileId}', deltax.businessProfileId)
      .replace('{mediaPlanId}', mediaPlanId)

    const promise = axiosInstance.patch(updateStatusUrl, JSON.stringify(paramsObject));
    return promise;
  }

  GetPurchaseOrdersByApprovedBuyId (approvedBuyId) {
    const getApprovedBuy = deltax.apiBaseUrl
      .concat(`portal/${deltax.BillingPortalId}/approvedBuy/${approvedBuyId}`)
    const promise = axiosInstance.get(getApprovedBuy)
    return promise
  }

  LinkPurchaseOrderToEstimates (purchaseOrderId, estimateId) {
    let url = deltaXApiUrl.approvedBuys.linkPurchaseOrder
      .replace('{portalId}', deltax.BillingPortalId)
      .replace('{approvedBuyId}', estimateId);

    let promise = axiosInstance({
      method: "PUT",
      url: url,
      data: purchaseOrderId
    });
    return promise;
  }

  UpdateMediaPlanStatus (contractId, status, mediaPlanId, selectedApprovalGroup) {
    var paramsObject;
    if (selectedApprovalGroup > 0) {
      paramsObject = "[{'Name':'CONTRACTID','Value':" + contractId + "},{'Name':'STATUS','Value':" + status + "},{'Name':'APPROVALGROUPID','Value':" + selectedApprovalGroup + "}]"
    } else {
      paramsObject = "[{'Name':'CONTRACTID','Value':" + contractId + "},{'Name':'STATUS','Value':" + status + "}]"
    }
    const updateStatusUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`MediaPlans/${mediaPlanId}`)
    const promise = axiosInstance.patch(updateStatusUrl, JSON.stringify(paramsObject));
    return promise;
  }

  UpdateMediaPlanVersionStatus (mpvId, status) {
    var paramsObject = "[{'Name':'STATUS','Value':" + status + "}]"
    const updateStatusUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`MediaPlanVersions/${mpvId}`)
    const promise = axiosInstance.patch(updateStatusUrl, JSON.stringify(paramsObject));
    return promise;
  }

  createPurchaseOrder (portalId, data) {
    const url = deltaXApiUrl.purchaseOrders.create
      .replace('{portalId}', deltax.BillingPortalId);

    const promise = axiosInstance({
      url: url,
      method: 'POST',
      data: data
    });
    return promise
  }
  uploadPurchaseOrderAttachment (portalId, purchaseOrderId, formData) {
    const url = deltaXApiUrl.purchaseOrders.upload
      .replace('{portalId}', deltax.BillingPortalId)
      .replace('{purchaseOrderId}', purchaseOrderId);

    const promise = axiosInstance({
      method: "POST",
      url: url,
      data: formData
    });
    return promise;
  }
  getApprovalUsers (id) {
    const url = deltaXApiUrl.contracts.getApprovalUsers
      .replace('{portalId}', deltax.BillingPortalId)
      .replace('{contractId}', id);
    const promise = axiosInstance({
      url,
      method: 'GET'
    })
    return promise;
  }
}
