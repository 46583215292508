export default {
  SearchEngines: {
    GoogleAds: 1,
    Bing: 2,
    Facebook: 5,
    Twitter: 6,
    LinkedIn: 7,
    YahooGemini: 8,
    DV360: 9,
    AMS: 10,
    Hotstar: 11,
    Voot: 12,
    Quora: 13,
    Baidu: 14,
    Amadeus: 15,
    AppsFlyer: 16,
    ZohoCRM: 17,
    GoogleAnalytics: 18,
    Magento: 19,
    MicrosoftDynamics: 20,
    Mixpanel: 21,
    AdobeAnalytics: 22,
    Salesforce: 23,
    Shopify: 24,
    Xandr: 25,
    MediaMath: 26,
    OutBrain: 27,
    Pinterest: 28,
    Naver: 29,
    Snapchat: 30,
    AdRoll: 31,
    Eskimi: 32,
    WooCommerce: 33,
    AppleSearchAds: 34,
    TikTokAds: 35,
    BigCommerce: 36,
    TTD: 37,
    GoogleMyBusiness: 38,
    Birdigo: 39,
    Reddit: 40,
    Instacart: 41,
    DeltaX: 42,
    DV360CampaignManager: 43,
    Sizmek: 44,
    Moloco: 45,
    Loblaws: 46,
    Criteo: 47,
    SA360: 48,
    GoogleDrive: 51
  },
  errorCodes: {
    "InvalidRequestData": 102
  }
};
