<template>
  <Form
    label-position="right"
    :label-width="200"
    :model="config"
    :rules="validationRules"
    ref="formAdwords"
  >
    <div v-show="showConfigForm">
      <Divider orientation="left">
        Accounts and Assets
      </Divider>

      <Form-Item label="Google Account" prop="bpseId" key="bpseId">
        <Row>
          <Row-Col v-if="!isPromotionFlow" span="18">
            <Select
              v-model="config.bpseId"
              placeholder="Select an account"
              :transfer="true"
              filterable
              :disabled="isExistingConfig"
            >
              <Option
                v-for="(item, index) in googleAccounts"
                :key="index"
                :value="item.Id"
                :disabled="item.IsOauthExpired"
                >{{ item.NickName }}</Option
              >
            </Select>
          </Row-Col>
          <Row-Col v-else span="18">
            <Select v-model="config.bpseId" :transfer="true" disabled>
              <Option :value="config.bpseId">{{ config.googleAccountId }}</Option>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Divider orientation="left">Campaign Settings</Divider>
      <Form-Item label="Campaign Goal" key="campaign.goal">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="selectedCampaignGoals"
              placeholder="Select a campaign goal"
              :transfer="true"
              filterable
              :multiple="true"
              :disabled="isEditPromoMode"
            >
              <Option
                v-for="(item, index) in customerConversionGoals"
                :key="index"
                :value="item.id"
                >{{ item.name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Campaign Type" prop="campaign.type" key="campaign.type">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.type"
              placeholder="Select an campaign type"
              :transfer="true"
              :disabled="isEditPromoMode"
              filterable
            >
              <Option
                v-for="(typeId, type) in computeCampaignType"
                :key="typeId"
                :value="Number(typeId)"
                >{{ type }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Subtype"
        prop="campaign.subtype"
        v-show="config.campaign.type == this.adwordsConstants.campaignType.App"
        key="campaign.subtype"
      >
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.campaign.subtype">
              <Radio
                :label="id"
                v-for="(id, subtype) in this.adwordsConstants.campaignSubType"
                :key="id"
                :disabled="isEditPromoMode"
              >
                <span> {{ subtype }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="App Store"
        prop="campaign.appStore"
        v-show="config.campaign.type == this.adwordsConstants.campaignType.App"
        key="campaign.appStore"
      >
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.campaign.appStore">
              <Radio
                :label="id"
                v-for="(id, store) in this.adwordsConstants.appStore"
                :key="id"
                :disabled="isExistingConfig"
              >
                <span> {{ store }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="App"
        prop="campaign.appId"
        v-show="config.campaign.type == this.adwordsConstants.campaignType.App"
        key="appId"
      >
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.campaign.appId" />
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Bidding"
        prop="campaign.appCampaignBiddingFocus"
        v-if="showAppCampaignBiddingFocus"
        key="campaign.appCampaignBiddingFocus"      >
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.appCampaignBiddingFocus"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(enumId, name) in computedAppCampaignBiddingFocus"
                :key="enumId"
                :value="Number(enumId)"
                >{{ name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Target"
        prop="campaign.target"
        v-if="showTargetUsers"
        key="campaign.target"
      >
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.target"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(enumId, name) in this.adwordsConstants.targetUsers"
                :key="enumId"
                :value="Number(enumId)"
                >{{ name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Target Cost"
        prop="campaign.targetCost"
        v-if="isTargetCostRequired"
        key="campaign.targetCost"
      >
        <Row>
          <Row-Col span="5">
            <InputNumber
              :formatter="value => `${bpCurrencySymbol} ${value}`"
              :parser="value => value.replace(bpCurrencySymbol, '').trim()"
              :min="0"
              v-model="config.campaign.targetCost"
            >
            </InputNumber>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
      v-if="(config.campaign.type == this.adwordsConstants.campaignType.App && config.campaign.appCampaignBiddingFocus == this.adwordsConstants.appCampaignBiddingFocus['In-app action value'])"
      prop="campaign.targetRoasValue"
      key="campaign.targetRoasValue">
      <Row>
        <Row-Col span="3">Target ROAS <span><i class="fa fa-question-circle"></i></span></Row-Col>
          <Row-Col span="4">
            <i-input v-model="config.campaign.targetRoasValue" type="number">
            <span slot="append">%</span>
            </i-input>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="In-App Actions"
        prop="campaign.conversionActionIds"
        v-show="showConversionActions"
        key="campaign.conversionActionIds"
      >
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.conversionActionIdsList"
              :disabled="Object.keys(conversionMetrics).length == 0"
              :placeholder="conversionMetricText"
              :transfer="true"
              filterable
              :multiple="allowMultipleConversionActions"
              :key="rerenderConversionSelect"
            >
              <template v-for="(conversionsList, header) in conversionMetrics">
                <Option disabled :key="header" :value="header">
                  <span class="ac-conv-header"> {{ header }} </span>
                </Option>
                <Option
                  v-for="conversion in conversionsList"
                  :key="conversion.id"
                  :value="Number(conversion.id)"
                  >{{ conversion.text }}</Option
                >
              </template>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Networks"
        prop="campaign.networks"
        v-show="config.campaign.type == this.adwordsConstants.campaignType.Search"
        key="campaign.networks"
      >
        <CheckboxGroup v-model="config.campaign.networks">
          <Checkbox
            v-for="(name, id) in this.adwordsConstants.campaignNetworks"
            :key="id"
            :label="id"
            :value="id"
            :disabled="isEditPromoMode"
          >
            <span> {{ name }} </span>
          </Checkbox>
        </CheckboxGroup>
      </Form-Item>
      <Form-Item
        label="Bidding"
        prop="campaign.biddingStrategyType"
        v-show="config.campaign.type != this.adwordsConstants.campaignType.App"
        key="campaign.biddingStrategyType"
      >
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.biddingStrategyType"
              class="padding-bottom-ten"
              :transfer="true"
              filterable
              :disabled="isEditPromoMode"
            >
              <Option
                v-for="bidType in computedBidStrategies"
                :key="bidType.id"
                :value="Number(bidType.id)"
                :disabled="shouldDisablePerformaceMaxCampaign(bidType.id)"
                >{{ bidType.name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
        <Row v-if="config.campaign.biddingStrategyType == adwordsConstants.adwordsBiddingStrategyTypes.ManualCpc">
          <Row-Col>
              <Checkbox v-model="config.campaign.enhancedCpcEnabled" :disabled="isEditPromoMode">Help Increase Conversions with Enhanced CPC</Checkbox>
          </Row-Col>
          <Row-Col span="12">
            <Form-Item
              class="form-item-title no-margin"
              prop="campaign.manualCpc"
              :label-width="140"
              label="Manual CPC limit"
              key="campaign.manualCpc"
            >
              <i-input :disabled="isEditPromoMode" v-model="config.campaign.manualCpc" />
            </Form-Item>
          </Row-Col>
        </Row>
        <Row
          v-if="config.campaign.biddingStrategyType == adwordsConstants.adwordsBiddingStrategyTypes.MaximizeClicks"
          >
          <Row-Col span="1" class="margin-right-nfive">
              <Checkbox v-model="config.campaign.isMaxCPClimitSet" :disabled="isEditPromoMode"></Checkbox>
          </Row-Col>
          <Row-Col span="12">
            <Form-Item
              class="form-item-title no-margin"
              prop="campaign.maxCpcLimit"
              :label-width="140"
              label="Max CPC limit"
              key="campaign.maxCpcLimit"
            >
              <i-input :disabled="!config.campaign.isMaxCPClimitSet || isEditPromoMode" v-model="config.campaign.maxCpcLimit" />
            </Form-Item>
          </Row-Col>
        </Row>
        <Row
          v-show="config.campaign.biddingStrategyType == adwordsConstants.adwordsBiddingStrategyTypes.TargetImpressionShare"
        >
          <Row>
            <Row-Col span="12">
              <Form-Item
                class="form-item-title"
                label="Ad location"
                prop="campaign.adLocation"
                :label-width="140"
                key="campaign.adLocation"
              >
                <RadioGroup
                  v-model="config.campaign.adLocation" vertical>
                  <Radio :label=2 :disabled="isEditPromoMode"><span>Anywhere on results page</span></Radio>
                  <Radio :label=3 :disabled="isEditPromoMode"><span>Top of results page</span></Radio>
                  <Radio :label=4 :disabled="isEditPromoMode"><span>Absolute top of results page</span></Radio>
                </RadioGroup>
              </Form-Item>
            </Row-Col>
          </Row>
          <Row>
            <Row-Col span="12">
              <Form-Item
                  class="form-item-title"
                  prop="campaign.targetImpression"
                  :label-width="140"
                  label="Target Impression"
                  key="campaign.targetImpression"
              >
                <i-input class="append-percentage" prefix="%" v-model="config.campaign.targetImpression" :disabled="isEditPromoMode">
                  <span slot="append">%</span>
                </i-input>
              </Form-Item>
            </Row-Col>
          </Row>
          <Row>
            <Row-Col span="12">
              <Form-Item
                    class="form-item-title"
                    prop="campaign.maxCpcLimit"
                    :label-width="140"
                    label="Max CPC limit"
                    key="campaign.maxCpcLimit"
              >
                <i-input v-model="config.campaign.maxCpcLimit" :disabled="isEditPromoMode" />
              </Form-Item>
            </Row-Col>
          </Row>
        </Row>
      </Form-Item>

      <Form-Item
        prop="campaign.targetRoasValue"
        key="campaign.targetRoasValueAndCPA"
        v-if="(this.showTargetCpa || this.showTargetRoas) && (config.campaign.type == this.adwordsConstants.campaignType['Performance Max'] || config.campaign.type == this.adwordsConstants.campaignType.Display || config.campaign.type == this.adwordsConstants.campaignType.App || config.campaign.type == this.adwordsConstants.campaignType.Search) || config.campaign.type == this.adwordsConstants.campaignType['Demand Gen']"
      >
        <Row v-if="config.campaign.type == this.adwordsConstants.campaignType['Performance Max'] || config.campaign.type == this.adwordsConstants.campaignType.Search">
          <Row-Col span="18">
          <Checkbox v-model="config.campaign.targetCostPerActionEnabled" :disabled="isEditPromoMode">
            <span v-if="showTargetCpa">Set a target cost per action (optional)</span>
            <span v-if="showTargetRoas">Set a return on ad spend (optional)</span>
          </Checkbox>
          </Row-Col>
        </Row>
        <Row
          v-if="(config.campaign.targetCostPerActionEnabled && config.campaign.type == this.adwordsConstants.campaignType['Performance Max']) || config.campaign.type == this.adwordsConstants.campaignType.Display"
        >
        <Row v-if="showTargetRoas">
        <Row-Col span="3">Target ROAS <span><i class="fa fa-question-circle"></i></span></Row-Col>
          <Row-Col span="4">
            <i-input v-model="config.campaign.targetRoasValue" type="number" :disabled="isEditPromoMode">
            <span slot="append">%</span>
            </i-input>
          </Row-Col>
        </Row>
        </Row>
        <Row
          v-if="config.campaign.targetCostPerActionEnabled || config.campaign.type == this.adwordsConstants.campaignType.Display || config.campaign.type == this.adwordsConstants.campaignType.App || config.campaign.type == this.adwordsConstants.campaignType['Demand Gen']"
        >
        <Row v-if="showTargetCpa">
        <Row-Col span="3">Target CPA <span><i class="fa fa-question-circle"></i></span></Row-Col>
          <Row-Col span="4">
            <i-input v-model="config.campaign.targetBidAmount" type="number" :disabled="isEditPromoMode">
            <span slot="prepend">{{ bpCurrencySymbol }}</span>
            </i-input>
          </Row-Col>
        </Row>
        </Row>
      </Form-Item>

      <Form-Item label="Campaign per location"
        prop="campaign.locationMultiplier"
        v-show="config.campaign.type != this.adwordsConstants.campaignType['Performance Max'] && !isPromotionFlow"
        key="campaign.locationMultiplier"
        >
        <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.locationMultiplier">
              <Radio :label=1><span>1 Campaign for all locations</span></Radio>
              <Radio :label=2><span>1 Campaign per location</span></Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Negative Keyword List" prop="campaign.negativeKeywordListIds"
      v-show="config.campaign.type == this.adwordsConstants.campaignType.Search"
      key="campaign.negativeKeywordListIds">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.negativeKeywordListIds"
              placeholder="Select"
              filterable
              multiple
              :disabled="isEditPromoMode"
            >
              <Option
                v-for="(keyword, index) in negativeKeywordLists"
                :value="keyword.id"
                :key="index"
                >{{ keyword.name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Bid Adjustment"
        prop="campaign.bidAdjustment"
        v-show="config.campaign.type == this.adwordsConstants.campaignType.Search"
        key="campaign.bidAdjustment"
      >
        <Row>
          <Row-Col span="18">
          <Checkbox v-model="config.campaign.bidAdjustmentType" :disabled="isEditPromoMode">
            <span>Device Type</span>
          </Checkbox>
          </Row-Col>
        </Row>
        <Row
          :class="id> 0?'padding-top-fifteen':''"
          v-show="config.campaign.bidAdjustmentType"
          v-for="(deviceBid, id) in config.campaign.deviceBidModifiers"
          :key="id"
        >
          <Row-Col span="2">
            {{deviceBid.name}}
          </Row-Col>
          <Row-Col span="4" offset="1">
            <Select
              v-model="deviceBid.operand"
              :transfer="true"
            >
              <Option
                v-for="(name, enumId) in adwordsConstants.changeOptions"
                :key="enumId"
                :value="Number(enumId)"
                >{{ name }}</Option
              >
            </Select>
          </Row-Col>
          <Row-Col span="3">
            <Form-Item
              :prop="`campaign.deviceBidModifiers.${id}.value`"
              :rules="validationRules.deviceBidModifierValue"
              class="dbm-value"
            >
              <i-input
              class="append-percentage"
              prefix="%"
              v-model="deviceBid.value" >
                  <span slot="append">%</span>
              </i-input>
            </Form-Item>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Ad group Type"
      v-show="showAdgroupType"
      >
        <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.adgroupType" >
              <Radio label="Standard" v-if="config.campaign.type == this.adwordsConstants.campaignType.Search" :disabled="isEditPromoMode"><span>Standard</span></Radio>
              <Radio label="Display" v-if="config.campaign.type == this.adwordsConstants.campaignType.Display"><span>Display</span></Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Campaign Schedule" v-show="!isPromotionFlow">
        <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.scheduling">
              <Radio :label=1><span>Run Continuously</span></Radio>
              <Radio :label=2><span>Use LineItem Schedule</span></Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Ad Schedule" v-show="!isPromotionFlow">
        <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.schedule.type">
              <Radio :label=1><span>Always On</span></Radio>
              <Radio :label=3><span>Choose Schedule</span></Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
        <Row>
          <Form-Item  prop="campaign.schedule" class="schedule-configuration" key="campaign.schedule">
            <ScheduleConfiguration
              @updateSchedule="updateSchedule"
              v-show="config.campaign.schedule.type == 3"
              :scheduleConfiguration="{ dayParting_schedules: config.campaign.schedule.dayParting_schedules }"
              :isRnFConfig="false"
            />
          </Form-Item>
        </Row>
      </Form-Item>
      <Form-Item label="Location Mode" prop="campaign.locationMode" key="campaign.locationMode" v-show="!isPromotionFlow">
          <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.locationMode">
                <Radio :label="adwordsConstants.locationMode.Standard"><span>Standard</span></Radio>
                <Radio :label="adwordsConstants.locationMode.Hyperlocal"><span>Hyperlocal</span></Radio>
            </RadioGroup>
          </Row-Col>
          </Row>
      </Form-Item>
      <Form-Item label="Campaign Status" prop="campaign.status" v-show="!isPromotionFlow" key="campaign.status">
        <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.status">
              <Radio v-for="(name, id) in adwordsConstants.status" :label="Number(id)" :key="id">
                <span> {{ name }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <template v-if="isWizardFlow">
        <Divider orientation="left">KPI</Divider>
        <Form-Item label="KPI" prop="kpiMetricId" key="kpiMetricId">
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.kpiMetricId"
                placeholder="Select a kpi metric"
                :transfer="true"
                filterable
                :disabled="isExistingConfig"
              >
                <Option
                  v-for="dataParameter in dataParameters"
                  :key="dataParameter.id"
                  :value="dataParameter.id"
                  >{{ dataParameter.name }}</Option
                >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
      </template>

      <Form-Item label="Configuration Name" prop="name" v-show="!isPromotionFlow" key="name">
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.name" />
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Campaign Name" prop="campaign.name.userInput" key="campaign.name.userInput">
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.campaign.name.userInput" :disabled="isNomenclatureEnabled || isEditPromoMode"/>
          </Row-Col>
        </Row>
      </Form-Item>
    </div>
  </Form>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  CheckboxGroup,
  Checkbox,
  InputNumber
} from "iview";
import { mapState } from "vuex";
import { APIService } from "./../ApiService.js";
import ScheduleConfiguration from "./ScheduleConfiguration.vue";
import * as adwordsConstants from "../Constants/AdwordsEnums.js";
import CLHelperMethodsMixin from "../Mixins/CLHelperMethods.js";

export default {
  props: {
    loadExistingConfigId: { default: "" },
    showConfigForm: { default: false },
    isExistingConfig: { default: false },
    nomenclatureMacro: { default: null },
    isPromotionFlow: { type: Boolean, default: false },
    defaultFormConfig: Object,
    isEditPromoMode: { type: Boolean, default: false }
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Option,
    "i-input": Input,
    CheckboxGroup,
    Checkbox,
    ScheduleConfiguration,
    InputNumber
  },
  data () {
    return {
      adwordsConstants: {},
      config: {},
      defaultConfig: {},
      checkIfValid: false,
      negativeKeywordLists: [],
      isWithinRange: false,
      loadingExistingConfig: false,
      validationRules: {
        bpseId: [
          {
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (isNaN(value) || value <= 0)) {
                callback(new Error("Please select an account"));
              }
              callback();
            }
          }
        ],
        "campaign.type": [
          { required: true, message: 'Please select a campaign type' }
        ],
        "campaign.name.userInput": [
          { required: true, message: 'Please select a campaign name' }
        ],
        "campaign.biddingStrategyType": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.checkIfValid && this.config.campaign.type == this.adwordsConstants.campaignType.Search && !(value > 0)) {
                callback(new Error("Please select a bidding type"));
              }
              callback();
            }
          }
        ],
        name: [
          { required: true, message: "Please enter a configuration name" }
        ],
        "campaign.locationMultiplier": [
          {
            required: true, message: "Please select the location settings"
          }
        ],
        "campaign.maxCpcLimit": [
          {
            required: false,
            validator: (rule, value, callback) => {
              if (this.checkIfValid &&
              (
                (this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.MaximizeClicks && this.config.campaign.isMaxCPClimitSet) ||
                this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.TargetImpressionShare) &&
              (!value || isNaN(value) || value < 0)) {
                callback(new Error("Please enter valid amount"));
              }
              callback();
            }
          }
        ],
        "campaign.targetImpression": [
          {
            required: false,
            validator: (rule, value, callback) => {
              if (this.checkIfValid &&
              this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.TargetImpressionShare &&
              (!value || isNaN(value) || (value < 1 || value > 100))) {
                callback(new Error("Please enter valid amount between 1-100"));
              }
              callback();
            }
          }
        ],
        "config.campaign.adLocation": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.checkIfValid &&
              this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.TargetImpressionShare &&
              (!value || isNaN(value) || value <= 0)) {
                callback(new Error("Please select Ad location"));
              }
              callback();
            }
          }
        ],
        "deviceBidModifierValue": [
          {
            validator: (rule, value, callback) => {
              if (this.checkIfValid && this.config.campaign.bidAdjustmentType && value && (!/^\d+$/.test(value) || parseInt(value) < 0 || parseInt(value) > 100)) {
                callback(new Error("Please select a whole number between 0 and 100"));
              }
              callback();
            }
          }
        ],
        "campaign.schedule": [{
          validator: (rule, value, callback) => {
            if (this.isWithinRange) {
              callback(new Error("Please provide a valid time slot"))
            }
            callback()
          }
        }],
        kpiMetricId: [
          {
            required: true,
            type: "integer",
            message: "The Kpi Metric is needed is required",
            trigger: "blur"
          }
        ],
        "campaign.targetRoasValue": [
          {
            validator: (rule, value, callback) => {
              if ((this.targetCostPerActionEnabled || (this.showTargetRoas && this.config.campaign.type != this.adwordsConstants.campaignType['Performance Max']) || (this.config.campaign.type == this.adwordsConstants.campaignType.App && this.config.campaign.appCampaignBiddingFocus == this.adwordsConstants.appCampaignBiddingFocus['In-app action value'])) && (!value || isNaN(value))) {
                callback(new Error("Please enter target ROAS"));
              } else if (((this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.TargetCpa) || (this.showTargetCpa && this.config.campaign.type != this.adwordsConstants.campaignType['Performance Max'] && this.config.campaign.type != this.adwordsConstants.campaignType.Search)) && (!this.config.campaign.targetBidAmount || isNaN(this.config.campaign.targetBidAmount))) {
                callback(new Error("Please enter target CPA"));
              }
              callback();
            }
          }
        ],
        "campaign.target": [
          {
            required: true,
            type: "number",
            message: "Please select the kind of users to target"
          }
        ],
        "campaign.appCampaignBiddingFocus": [
          { required: true, message: 'Please select a Bidding type' }
        ],
        "campaign.targetCost": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value <= 0 && this.checkIfValid && this.isTargetCostRequired) {
                callback(new Error("Please select a Target cost per install"));
              }
              callback();
            }
          }
        ],
        "campaign.conversionActionIds": [
          {
            required: false,
            validator: (rule, value, callback) => {
              if (
                value &&
                this.checkIfValid &&
                this.config.campaign.type == this.adwordsConstants.campaignType.App &&
                this.showConversionActions && (!Array.isArray(value) || value.length == 0)
              ) {
                if (this.allowMultipleConversionActions) {
                  if (!Array.isArray(value) || value.length == 0) {
                    callback(new Error("Please select a conversion"));
                  }
                } else if (!value) {
                  callback(new Error("Please select a conversion"));
                }
              }
              callback();
            }
          }
        ],
        "campaign.appId": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value && this.checkIfValid && this.config.campaign.type == this.adwordsConstants.campaignType.App) {
                callback(new Error("Please enter the app store URL/Id"));
              }
              callback();
            }
          }
        ],
        "campaign.manualCpc": [
          {
            validator: (rule, value, callback) => {
              if (this.checkIfValid && this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.ManualCpc && (!value || isNaN(value) || value < 0)) {
                callback(new Error("Please enter valid amount"));
              }
              callback();
            }
          }
        ]
      },
      rerenderConversionSelect: 1,
      conversionMetrics: {},
      conversionMetricText: "Select In-App Actions",
      customerConversionGoals: [],
      selectedCampaignGoals: [],
      dataBeingFetchedforBpseId: false,
      alreadyfetchedConversionMetricsBpseId: 0
    };
  },
  mixins: [CLHelperMethodsMixin],
  created () {
    this.adwordsConstants = adwordsConstants.adwordsEnums
    this.config = this.getDefaultConfig();
    this.defaultConfig = JSON.parse(JSON.stringify(this.config))
    this.config.campaign.isMaxCPClimitSet = Boolean(this.config.campaign.isMaxCPClimitSet);
  },
  watch: {
    "config.bpseId": function (bpseId) {
      if (bpseId > 0) {
        this.config.googleAccountId = this.googleAccounts.find(
          item => item.Id == this.config.bpseId
        ).CustomerId;
        if (this.isNomenclatureEnabled) {
          this.config.campaign.name.userInput = this.nomenclatureMacro.campaign
        } if (this.config.campaign.type == this.adwordsConstants.campaignType.Search) {
          this.fetchNegativeKeywords(bpseId);
        }
        if (this.config.campaign.type == this.adwordsConstants.campaignType.App) {
          this.getConversionMetrics(bpseId);
        }
        if (!this.dataBeingFetchedforBpseId) {
          this.customerConversionGoals = []
          this.config.campaign.campaignConversionGoals = []
          this.selectedCampaignGoals = []
          this.getConversionGoals(bpseId)
        }
      }
    },
    "config.campaign.biddingStrategyType": function (biddingStrategyType) {
      if (biddingStrategyType > 0 && !this.loadingExistingConfig && this.defaultConfig.campaign.biddingStrategyType != biddingStrategyType) {
        this.config.campaign.targetBidAmount = null;
        this.config.campaign.isMaxCPClimitSet = false;
        this.$set(this.config.campaign, 'maxCpcLimit', null);
        this.$set(this.config.campaign, 'targetImpression', null);
        this.config.campaign.enhancedCpcEnabled = false;
        this.config.campaign.targetRoasValue = 0;
      }
    },
    loadExistingConfigId (id) {
      if (id) {
        this.loadingExistingConfig = true
        let config = JSON.parse(this.campaignLauncherConfigs[id].config);
        config.bpseId = Number(config.bpseId);
        this.config = JSON.parse(JSON.stringify(config))
        if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name)
        }
        if (!this.config.campaign.negativeKeywordListIds) {
          this.config.campaign.negativeKeywordListIds = []
        }
        if (this.config.campaign.networks && this.config.campaign.networks.length > 0) {
          this.config.campaign.networks = this.config.campaign.networks.map(String)
        }
        if (this.config.campaign.type == this.adwordsConstants.campaignType.Search) {
          this.config.campaign.adgroupType = "Standard"
        }
        if (this.config.campaign.type == this.adwordsConstants.campaignType.Display) {
          this.config.campaign.adgroupType = "Display"
        }
        if (this.config.campaign.type == this.adwordsConstants.campaignType.App) {
          this.config.campaign.adgroupType = "Unspecified"
        }
        this.config.adgroup.type = this.getKeyByValue(this.adwordsConstants.adgroupType, this.config.campaign.adgroupType)
        if (this.config.campaign.networks && this.config.campaign.networks.length > 0) {
          this.config.campaign.networks = this.config.campaign.networks.map(String)
        }
        if (this.config.campaign.bidAdjustmentType && this.config.campaign.bidAdjustmentType == 1) {
          this.config.campaign.bidAdjustmentType = true
        }
        if (this.config.campaign.conversionActionIdsList && this.config.campaign.conversionActionIdsList.length > 0) {
          this.config.campaign.conversionActionIdsList = this.config.campaign.conversionActionIdsList.map(Number)
        }
        var deviceBidModifiers = this.getDeviceBidModifiers();
        if (this.config.campaign.deviceBidModifiers) {
          this.config.campaign.deviceBidModifiers.forEach(x => {
            var dbm = deviceBidModifiers[x.type - 2]
            dbm.value = x.value;
            dbm.name = this.adwordsConstants.deviceTypes[x.type];
            dbm.operand = x.operand;
          })
        }
        this.config.campaign.deviceBidModifiers = deviceBidModifiers;
        if (this.config.campaign.schedule.type != 3) {
          this.config.campaign.schedule.dayParting_schedules = [{
            startMinute: 0,
            endMinute: 1440,
            days: [0, 1, 2, 3, 4, 5, 6],
            timezoneType: 'USER'
          }];
        }
        if (!this.config.campaign.locationMode) {
          this.config.campaign.locationMode = this.adwordsConstants.locationMode.Standard
        }
        this.customerConversionGoals = []
        this.selectedCampaignGoals = []
        if (this.config.campaign.campaignConversionGoals && this.config.campaign.campaignConversionGoals.length > 0) {
          this.dataBeingFetchedforBpseId = true
          this.setConversionGoalsInConfig(Number(this.config.bpseId))
        }
        this.$nextTick(() => {
          this.config.bpseId = Number(this.config.bpseId);
          this.loadingExistingConfig = false
        });
      } else {
        this.config = this.getDefaultConfig();
      }
    },
    isWithinRange: function (value) {
      this.$refs["formCampaignConfig"].validateField("adset.schedule");
    },
    "config.campaign.type": function (val) {
      if (this.config.campaign.type == this.adwordsConstants.campaignType['Performance Max']) {
        if (!this.loadingExistingConfig) {
          this.config.campaign.biddingStrategyType = 10;
        }
      } else if (this.config.campaign.type == this.adwordsConstants.campaignType.Display) {
        this.config.campaign.adgroupType = "Display"
      } else if (this.config.campaign.type == this.adwordsConstants.campaignType.Search) {
        this.config.campaign.adgroupType = "Standard"
      } else if (this.config.campaign.type == this.adwordsConstants.campaignType.App && this.config.bpseId) {
        this.getConversionMetrics(this.config.bpseId);
        this.config.campaign.adgroupType = "Unspecified"
      } else if (this.config.campaign.type == this.adwordsConstants.campaignType['Demand Gen']) {
        this.config.campaign.adgroupType = "Unspecified"
      }
      this.config.adgroup.type = this.getKeyByValue(this.adwordsConstants.adgroupType, this.config.campaign.adgroupType)
    },
    "config.campaign.subtype" (subtype) {
      if (subtype == this.adwordsConstants.campaignSubType["App Engagement"]) {
        this.config.campaign.appCampaignBiddingFocus =
        this.adwordsConstants.appCampaignBiddingFocus["In-app actions"];
      }
    },
    "config.campaign.appCampaignBiddingFocus" (bidFocus) {
      if (bidFocus == this.adwordsConstants.appCampaignBiddingFocus["In-app actions"]) {
        this.config.campaign.target =
        this.adwordsConstants.targetUsers["Users likely to perform an in-App action"];
      }
    },
    "config.campaign.maxCpcLimit" (value) {
      if (value) {
        this.config.campaign.isMaxCPClimitSet = true
      }
    },
    "selectedCampaignGoals" (value) {
      if (value && value.length) {
        const filteredGoals = this.customerConversionGoals.filter(goal => (goal.value.biddable && !value.includes(goal.id)) || (!goal.value.biddable && value.includes(goal.id)));
        this.config.campaign.campaignConversionGoals = filteredGoals.map(goal => goal.value);
      }
    }
  },
  computed: {
    ...mapState([
      "googleAccounts",
      "campaignLauncherConfigs",
      "isWizardFlow",
      "dataParameters",
      "bpCurrencySymbol"
    ]),
    isNomenclatureEnabled () {
      if (this.nomenclatureMacro && this.nomenclatureMacro.campaign) {
        return true;
      }
      return false;
    },
    computedBidStrategies () {
      var allBidStrategies = this.adwordsConstants.adwordsBiddingStrategies
      if (this.config.campaign.type == this.adwordsConstants.campaignType.Search) {
        const namesToFilter = ["Target CPA", "Target ROAS"];
        allBidStrategies = allBidStrategies.filter(item => !namesToFilter.includes(item.name));
      }
      if (this.config.campaign.type == this.adwordsConstants.campaignType.Display) {
        allBidStrategies = allBidStrategies.filter(item => item.name !== "Target Impression Share");
      }
      if (this.config.campaign.type == this.adwordsConstants.campaignType["Performance Max"]) {
        allBidStrategies = [
          { id: 10, name: 'Maximize Conversions' },
          { id: 11, name: 'Maximize Conversion Value' }
        ]
      }
      if (this.config.campaign.type == this.adwordsConstants.campaignType["Demand Gen"]) {
        const namesToFilter = ["Maximize Clicks", "Maximize Conversions", "Target CPA"];
        allBidStrategies = allBidStrategies.filter(item => namesToFilter.includes(item.name));
      }
      return allBidStrategies
    },
    showTargetRoas () {
      return (this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.MaximizeConversionValue && this.config.campaign.type == this.adwordsConstants.campaignType['Performance Max']) ||
      (this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.TargetRoas)
    },
    showTargetCpa () {
      return ((this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.MaximizeConversions && this.config.campaign.type != this.adwordsConstants.campaignType.Display && this.config.campaign.type != this.adwordsConstants.campaignType['Demand Gen']) ||
       this.config.campaign.biddingStrategyType == this.adwordsConstants.adwordsBiddingStrategyTypes.TargetCpa ||
       (this.config.campaign.type == this.adwordsConstants.campaignType.App && this.config.campaign.appCampaignBiddingFocus == this.adwordsConstants.appCampaignBiddingFocus["Install Volume"]))
    },
    showAppCampaignBiddingFocus () {
      return this.config.campaign.type == this.adwordsConstants.campaignType.App
    },
    showConversionActions () {
      return (
        this.config.campaign.type == this.adwordsConstants.campaignType.App && this.config.campaign.target != this.adwordsConstants.targetUsers["All Users"] && Object.keys(this.conversionMetrics).length
      );
    },
    allowMultipleConversionActions () {
      return this.config.campaign.subtype == this.adwordsConstants.campaignSubType["App Engagement"] ||
      (this.config.campaign.subtype == this.adwordsConstants.campaignSubType["App Installs"] &&
      this.config.campaign.target == this.adwordsConstants.targetUsers["Users likely to perform an in-App action"]) ||
      (this.config.campaign.subtype == this.adwordsConstants.campaignSubType["App Installs"] &&
      this.config.campaign.appCampaignBiddingFocus == this.adwordsConstants.appCampaignBiddingFocus["In-app action value"])
    },
    showTargetUsers () {
      return (
        this.config.campaign.type == this.adwordsConstants.campaignType.App &&
        this.config.campaign.appCampaignBiddingFocus ==
        this.adwordsConstants.appCampaignBiddingFocus["Install Volume"]
      );
    },
    showAdgroupType () {
      return this.config.campaign.type == this.adwordsConstants.campaignType.Search || this.config.campaign.type == this.adwordsConstants.campaignType.Display
    },
    computeCampaignType () {
      return this.adwordsConstants.campaignType
    },
    computedAppCampaignBiddingFocus () {
      let allAppCampaignBiddingFocus = JSON.parse(JSON.stringify(this.adwordsConstants.appCampaignBiddingFocus))
      if (this.config.campaign.subtype == this.adwordsConstants.campaignSubType["App Engagement"]) {
        delete allAppCampaignBiddingFocus["Install Volume"];
      }
      return allAppCampaignBiddingFocus
    },
    isTargetCostRequired () {
      return (this.config.campaign.type == this.adwordsConstants.campaignType.App && this.config.campaign.appCampaignBiddingFocus == this.adwordsConstants.appCampaignBiddingFocus['In-app actions'])
    }
  },
  methods: {
    updateSchedule (updatedSchedule) {
      this.config.campaign.schedule.dayParting_schedules = updatedSchedule.dayParting_schedules
      this.isWithinRange = updatedSchedule.isWithinRange
    },
    fetchNegativeKeywords (bpseId) {
      APIService.getAdwordsNegativeKeywords(bpseId).then(async response => {
        var data = await response.data.data
        this.negativeKeywordLists = data
      })
    },
    getConfig () {
      this.config.campaign.deviceBidModifiers = this.config.campaign.deviceBidModifiers.filter(x => x.value)
      if (this.isPromotionFlow || this.isWizardFlow) {
        return {
          name: this.config.name,
          businessProfileSearchEngineId: this.config.bpseId,
          configJson: this.config,
          status: true,
          isPromotion: true,
          isReachAndFrequencyConfig: false
        };
      }
      return this.config;
    },
    resetFields () {
      this.$refs["formAdwords"].resetFields();
      this.customerConversionGoals = []
      this.selectedCampaignGoals = []
    },
    isAdwordsFormValid (saveCallback) {
      // console.log(this.config);
      // console.log(JSON.stringify(this.config));
      this.checkIfValid = true;
      this.$refs["formAdwords"].validate((valid) => {
        if (valid) {
          saveCallback();
        }
        this.checkIfValid = false;
        this.$nextTick(function () {
          let errors = document.getElementsByClassName("ivu-form-item-error-tip");
          if (errors.length > 0) {
            errors[0].parentElement.scrollIntoView({ block: 'end' });
          }
        });
      })
    },
    getDefaultConfig () {
      let defaultConfig = {
        searchEngineTypeId: 1,
        name: "",
        bpseId: 0,
        googleAccountId: 0,
        adgroup: {
          type: 2
        },
        campaign: {
          adgroupType: "Standard",
          targetBidAmount: null,
          targetImpression: null,
          enhancedCpcEnabled: false,
          isMaxCPClimitSet: false,
          maxCpcLimit: null,
          adLocation: 2,
          deviceBidModifiers: [],
          biddingStrategyType: 9,
          bidAdjustmentType: false,
          schedule: {
            type: 1,
            dayParting_schedules: [ {
              startMinute: 0,
              endMinute: 1440,
              days: [0, 1, 2, 3, 4, 5, 6],
              timezoneType: 'USER'
            }]
          },
          name: {
            userInput: ""
          },
          type: 1,
          networks: [],
          status: 1,
          locationMultiplier: 1,
          scheduling: 2,
          locationMode: this.adwordsConstants.locationMode.Standard,
          manualCpc: 0,
          targetRoasValue: 0,
          subtype: 8,
          appId: "",
          appStore: 3,
          appCampaignBiddingFocus: 1,
          target: 1,
          targetCost: null,
          conversionActionIdsList: [],
          campaignConversionGoals: []
        },
        kpiMetricId: 0
      }
      // let allowedCampaignTypes = ['Search', 'Performance Max'];
      // Object.keys(this.adwordsConstants.campaignType)
      //   .forEach((key) => allowedCampaignTypes.includes(key) ||
      // delete this.adwordsConstants.campaignType[key]);
      defaultConfig.campaign.deviceBidModifiers = this.getDeviceBidModifiers()

      // set conversion goals when default form config have conversion goals
      if (this.defaultFormConfig && this.defaultFormConfig.campaign.campaignConversionGoals && this.defaultFormConfig.campaign.campaignConversionGoals.length > 0) {
        this.dataBeingFetchedforBpseId = true
        this.setConversionGoalsInConfig(Number(this.defaultFormConfig.bpseId))
      }
      return this.defaultFormConfig ? this.defaultFormConfig : defaultConfig;
    },
    getDeviceBidModifiers () {
      var deviceBidModifiers = []
      Object.keys(this.adwordsConstants.deviceTypes).forEach((key) => {
        var deviceinfo = {
          type: key,
          operand: 1,
          value: null,
          name: this.adwordsConstants.deviceTypes[key]
        }
        deviceBidModifiers.push(deviceinfo)
      });
      return deviceBidModifiers;
    },
    shouldDisablePerformaceMaxCampaign (bidId) {
      const disabledIds = [3, 9, 15];
      return disabledIds.includes(bidId) && this.config.campaign.type == this.adwordsConstants.campaignType['Performance Max'];
    },
    getKeyByValue (object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    getConversionMetrics (bpseId) {
      if (this.alreadyfetchedConversionMetricsBpseId != bpseId) {
        this.alreadyfetchedConversionMetricsBpseId = bpseId;
        this.conversionMetrics = {}
        this.conversionMetricText = "Fetching In-App Actions"
        APIService.getGoogleConversionActions(bpseId).then(response => {
          response.data.conversionMetrics.forEach(metrics => {
            this.$set(this.conversionMetrics, metrics.text, metrics.children)
          })
          this.conversionMetricText = "Select In-App Actions";
          this.rerenderConversionSelect ^= 1;
        }).catch(() => {
          this.conversionMetricText = "Failed to fetch In-App Actions"
        })
      }
    },
    async getConversionGoals (bpseId) {
      var response = await APIService.getConversionGoals(bpseId)
      response.data.forEach(item => {
        var conversionObject = {}
        let nameParts = item["name"].split('~')
        conversionObject["name"] = `${this.capitalizeFirstLetter(nameParts[0])} (${this.capitalizeFirstLetter(nameParts[1])})`
        conversionObject["value"] = {
          "category": item["category"],
          "origin": item["origin"],
          "biddable": item["biddable"]
        }
        conversionObject["id"] = `${item["category"]}${item["origin"]}`
        this.customerConversionGoals.push(conversionObject)
      })
    },
    capitalizeFirstLetter (string) {
      var pascalCaseString = ''
      if (string) {
        pascalCaseString = string.split('_').join(' ')
        pascalCaseString = pascalCaseString.charAt(0).toUpperCase() + pascalCaseString.slice(1).toLowerCase();
      }
      return pascalCaseString
    },
    async setConversionGoalsInConfig (bpseId) {
      await this.getConversionGoals(bpseId)
      var unselectedCampaignGoals = []
      this.config.campaign.campaignConversionGoals.forEach(goal => {
        unselectedCampaignGoals.push(`${goal["category"]}${goal["origin"]}`)
      })
      const filteredGoals = this.customerConversionGoals.filter(goal => (goal.value.biddable && !unselectedCampaignGoals.includes(goal.id)) || (!goal.value.biddable && unselectedCampaignGoals.includes(goal.id)));
      this.selectedCampaignGoals = filteredGoals.map(goal => goal.id);
      this.dataBeingFetchedforBpseId = false
    }
  }
};
</script>

<style src="../../../Styles/dx-iview.css"></style>

<style scoped>
.append-percentage ::v-deep .ivu-input-with-prefix {
    border-right: 0 !important;
}

.append-percentage ::v-deep .ivu-input-group-append{
    background: white !important;
}
.form-item-title ::v-deep .ivu-form-item-content{
  padding-left: 0
}
.form-item-title ::v-deep .ivu-form-item-error-tip{
  margin-left: 0
}
.form-item-title{
  text-align-last: start;
  display:flex;
}
.padding-bottom-ten{
  padding-bottom: 10px
}
.margin-right-nfive{
  margin-right: -5px;
}
.padding-top-fifteen{
  padding-top: 15px
}
.dbm-value ::v-deep .ivu-form-item-error-tip{
  margin-top: 5px;
  min-width: 300px;
  margin-left: 20px;
}
::v-deep .ivu-form-item-error-tip{
  min-width: 300px;
}
.dbm-value ::v-deep .ivu-form-item-content{
  padding-left: 20px;
}
.schedule-configuration ::v-deep .ivu-form-item-content{
  padding-left: 0px
}
::v-deep .ivu-input-with-prefix{
  padding-left: 7px
}
</style>
